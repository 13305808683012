export default function (options) {
    let defaults = {
            afterPartialIsLoaded: {},
            afterAction: {}
        },
        settings = $.extend(defaults, options);

    window.getAllValues = function (section) {
        return $(section).find('form').serializeArray();
    };

    let currentRequest = {
        abort: function () {
        }
    };

    function amountQty(e, plusOrMinus) {
        e.preventDefault();

        let field = $(e.target).parent().find('input.amount');
        let orderPer = $(field).data('order-per') ? parseInt($(field).data('order-per')) : 1;
        let currentVal = parseInt($(field).val());
        let newAmount;

        if (plusOrMinus === 'minus') {
            let minAmount = $(field).data('min-amount') ? parseInt($(field).data('min-amount')) : 1;
            newAmount = currentVal - orderPer;
            newAmount = newAmount < minAmount ? minAmount : newAmount;
        } else {
            newAmount = currentVal + orderPer;
        }

        $(field).attr('value', newAmount);
        $(field).val(newAmount).trigger('change');
    }

    function bindSectionUpdates(selector) {
        $(selector).each(function () {
            $(this).find('.qtyplus').off('click').click(function (e) {
                amountQty(e, 'plus');
            });
            $(this).find('.qtyminus').off('click').click(function (e) {
                amountQty(e, 'minus');
            });

            // MatchHeight CMS Cart / New Cart / Don't delete
            $(this).find('.cart__items .item .inner').matchHeight({
                byRow: true
            });
            $(this).find('.cart__gift .item .matching').matchHeight({
                byRow: true
            });
            $(this).find('.item .matching').matchHeight({
                byRow: true
            });
            $(this).find('.cms__cart__match').matchHeight({
                byRow: true
            });
            $(this).find('.address__item .matching').matchHeight({
                byRow: true
            });
            $(this).find('.address__item_order .matching').matchHeight({
                byRow: true
            });
            $(this).find('.address__item_addressbook .matching').matchHeight({
                byRow: true
            });

            $(this).find('button[data-action], .section-action[data-action]').each(function () {
                $(this).off('click').on('click', sectionAction);
            });

            $(this).find('button[data-render-partial]').each(function () {
                $(this).off('click').on('click', sectionRenderPartial);
            });

            $(this).find('input[data-action], select[data-action]').each(function () {
                $(this).off('change').on('change', sectionAction)
                    .off('keypress').on('keypress', function (e) {
                    if (e.which === 13) {
                        sectionAction(e);
                    }
                });
            });

            $(this).find('.customer__inputs .radio .customerType').change(function () {
                if ($(this).is(':checked')) {
                    $(this).parent().parent().find('.active').removeClass('active');
                    $(this).parent().addClass('active');
                }
                if ($('.customer__inputs .radio input[value="company"]').is(':checked')) {
                    $('.customer__inputs .company__fields').addClass('active');
                } else {
                    $('.customer__inputs .company__fields').removeClass('active');
                }
            });

            $(this).find('.normal__inputs .radio .radiobutton').change(function () {
                if ($(this).is(':checked')) {
                    $(this).parent().parent().find('.active').removeClass('active');
                    $(this).parent().addClass('active');
                }
            });

            $(this).find(".invoice__address .checkbox #invoiceAddressSameAsDelivery").change(function () {
                $(this).parent().toggleClass("active", this.checked);
                if ($('.invoice__address .checkbox #invoiceAddressSameAsDelivery').is(':checked')) {
                    $('.invoice__address .hidden__fields').removeClass('active');
                } else {
                    $('.invoice__address .hidden__fields').addClass('active');
                }
            });

            $(this).find(".register__account .checkbox #password").change(function () {
                $(this).parent().toggleClass("active", this.checked);
                if ($('.register__account .checkbox #password').is(':checked')) {
                    $('.register__account .hidden__fields').addClass('active');
                } else {
                    $('.register__account .hidden__fields').removeClass('active');
                }
            });
        });
    }

    function sectionAction(e) {
        e.preventDefault();

        let input = $(this ? this : e.target);
        let data = $.extend(true, {}, input.data());
        let action = data.action;
        let section = $(input).closest('[data-section$=".partials"]');
        let val = null;

        if (input.is('input')) {
            val = input.val();
        }
        if (input.is('select')) {
            val = input.children("option:selected").val();
        }

        if (val) {
            if (data.hasOwnProperty('id')) {
                data['id'] = data.id;
            }

            if (data.hasOwnProperty('property')) {
                data[data.property] = val;
                delete data.property;
            } else {
                data[input.attr('name')] = val;
            }
        }

        if (input.is('[data-form]')) {
            window.getAllValues(section).forEach(function (item) {
                data[item.name] = item.value;
            });
        }

        call('action', e, section, data, data.hasOwnProperty('noPartialUpdate'), action);
    }

    function sectionRenderPartial(e) {
        e.preventDefault();

        let input = $(this ? this : e.target);
        let data = $.extend(true, {}, input.data());
        if (data.hasOwnProperty('renderPartial')) {
            let section = $('[data-section$=".partials"][data-partial="' + data.renderPartial + '"]');
            call('renderPartial', e, section, data, false);
        }

    }

    function call(endpoint, event, section, data, skipInitiatingPartial = false, action = null) {
        currentRequest.abort();

        let partial = section.data('partial');

        // Gather all available parstials on the current page.
        let allPartials = [];
        $('[data-section$=".partials"]').each(function () {
            allPartials.push({
                partial: $(this).data('section').slice(0, -9) + '.' + $(this).data('partial'),
                data: $(this).data()
            })
        });

        let bodyData = $('body').data();
        currentRequest = $.ajax({
            type: 'POST',
            url: bodyData.baseUrl + '/api/front-end/section/' + endpoint,
            beforeSend: function () {
                if (currentRequest != null) {
                    currentRequest.abort();
                }
            },
            data: JSON.stringify({
                'language': bodyData.language,
                'partial': section.data('section') + '.' + section.data('partial'),
                'partialData': section.data(),
                'partials': allPartials,
                'partialOnly': data.hasOwnProperty('partialOnly'),
                'skipInitiatingPartial': skipInitiatingPartial,
                'data': data,
                'action': action
            }),
            dataType: 'json',
            success: function (data) {
                if (data.hasOwnProperty('refresh')) {
                    location.reload();
                } else {
                    if (data.hasOwnProperty('partials')) {
                        for (let partialIndex in data.partials) {
                            if (data.partials.hasOwnProperty(partialIndex)) {
                                let partialSections = data.partials[partialIndex];

                                for (let sectionIndex in partialSections) {
                                    if (partialSections.hasOwnProperty(sectionIndex)) {
                                        let partialSection = partialSections[sectionIndex];
                                        let sectionSelector = '[data-section$=".partials"][data-partial="' + partialIndex + '"]';

                                        if (sectionIndex !== 'std') {
                                            if (isNaN(parseInt(sectionIndex))) {
                                                sectionSelector += '[data-view="' + sectionIndex + '"]';
                                            }
                                            else {
                                                sectionSelector += '[data-id=' + sectionIndex + ']';
                                            }
                                        }

                                        $(sectionSelector).replaceWith(partialSection);

                                        if ((partialIndex === 'addressBook') && !$.fancybox.getInstance()) {
                                            $.fancybox.open({
                                                src: '.checkout-address__popup',
                                                type: 'inline',
                                                opts: {
                                                    afterShow: function (instance, current) {
                                                        // Rebind the section updates
                                                        bindSectionUpdates(sectionSelector);

                                                        // Call the partial callback if available
                                                        if (settings.afterPartialIsLoaded.hasOwnProperty(partialIndex)) {
                                                            settings.afterPartialIsLoaded[partialIndex](event);
                                                        }
                                                    }
                                                }
                                            });
                                        } else {
                                            // Rebind the section updates
                                            bindSectionUpdates(sectionSelector);

                                            // Call the partial callback if available
                                            if (settings.afterPartialIsLoaded.hasOwnProperty(partialIndex)) {
                                                settings.afterPartialIsLoaded[partialIndex](event);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // Call the action callback if available
                    if (settings.afterAction.hasOwnProperty(action)) {
                        settings.afterAction[action](event);
                    }

                    if (data.incomplete) {
                        for (let incompleteSection in data.incomplete) {
                            let sectionSelector = '[data-section$=".partials"][data-partial="' + incompleteSection + '"]';
                            $(sectionSelector).data('incomplete', data.incomplete[incompleteSection]);
                        }
                    }
                }
            },
            error: function (e) {
                $('[data-disable-on-error]').each(function () {
                    $(this).prop('disabled', true).addClass('disabled');
                });
                $('[data-empty-on-error]').each(function () {
                    $(this).empty();
                });

                if (e.hasOwnProperty('responseJSON') && e.responseJSON) {
                    let errors = e.responseJSON.errors;

                    let partialNode = $('[data-section$=".partials"][data-partial="' + partial + '"]');
                    partialNode.find('input').removeClass('error');
                    partialNode.find('[data-error-input]').hide().empty();

                    for (let field in errors) {
                        if (errors.hasOwnProperty(field)) {
                            let inputField = partialNode.find('[name="' + field + '"]');
                            if (inputField.val() || !inputField.has('[data-no-error-on-empty]')) {
                                partialNode.find('[name="' + field + '"]').addClass('error');
                                partialNode.find('[data-error-on-name="' + field + '"]').addClass('error');
                                partialNode.find('[data-error-input="' + field + '"]').html(errors[field]).show();
                            }
                        }
                    }
                }
            }
        });
    }

    bindSectionUpdates('[data-section$=".partials"]');

    $('[data-section$=".navigation"]').each(
        function () {
            $(this).find('button[data-step], .section-action[data-step]').each(function () {
                $(this).on('click', navigationAction);
            });
        }
    );

    function navigationAction(e) {
        e.preventDefault();

        let input = $(this ? this : e.target);

        if (!$(input).hasClass('fired')) {
            $(input).addClass('fired');

            let section = $(input).closest('[data-section$=".navigation"]');
            let allPartials = [];
            let sections = $('[data-section$=".partials"]:not([data-action-only])');
            sections.each(function () {
                allPartials.push({
                    partial: $(this).data('section').slice(0, -9) + '.' + $(this).data('partial'),
                    set: $(this).data('set'),
                    data: window.getAllValues(this)
                });
            });

            let bodyData = $('body').data();
            $.ajax({
                type: 'POST',
                url: bodyData.baseUrl + '/api/front-end/section/navigation',
                data: JSON.stringify({
                    'language': bodyData.language,
                    'partial': section.data('section'),
                    'step': input.data('step'),
                    'partials': allPartials,
                }),
                dataType: 'json',
                success: function (data) {
                    if (data.hasOwnProperty('redirect')) {
                        location = data.redirect;
                    }
                },
                error: function (e) {
                    $('[data-disable-on-error]').each(function () {
                        $(this).prop('disabled', true).addClass('disabled');
                    });
                    $('[data-empty-on-error]').each(function () {
                        $(this).empty();
                    });

                    if (e.hasOwnProperty('responseJSON')) {
                        let errors = e.responseJSON.errors;
                        for (let partial in errors) {
                            if (errors.hasOwnProperty(partial)) {
                                let partialNode = $('[data-section$=".partials"][data-partial="' + partial + '"]');
                                partialNode.find('input').removeClass('error');
                                partialNode.find('[data-error-input]').empty();

                                let partialMessages = errors[partial];

                                for (let field in partialMessages) {
                                    if (partialMessages.hasOwnProperty(field)) {
                                        partialNode.find('[name="' + field + '"]').addClass('error');
                                        partialNode.find('[data-error-on-name="' + field + '"]').addClass('error');
                                        partialNode.find('[data-error-input="' + field + '"]').html(partialMessages[field]);
                                    }
                                }
                            }
                        }

                        $("html, body").animate({scrollTop: Math.max(0, $('[data-section$=".partials"]').find('.error').first().offset().top - 50)}, "slow");
                    }

                    $(input).removeClass('fired');
                }
            });
        }
    }
}
