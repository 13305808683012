let bodyContainsCookieControlClass = document
    .getElementsByTagName('body')[0]
    .className.split(' ')
    .indexOf('cookie-control') > -1;

if (bodyContainsCookieControlClass) {
    if (Cookies.get('cookies-accepted') === 'true' || Cookies.get('essential-cookies-accepted') === 'true'
    ) {
        document.getElementsByTagName('body')[0].className += ' cookie-control--inverse';
    } else {
        Array.prototype.forEach.call(document.getElementsByClassName('js-cookie-confirmation'), function (button) {
            button.onclick = function () {
                Cookies.set('essential-cookies-accepted', 'true', { expires: 365 });
                Cookies.set('cookies-accepted', 'true', { expires: 365 });
                location.reload();
            };
        });

        Array.prototype.forEach.call(document.getElementsByClassName('js-cookie-confirmation-essentials'), function (button) {
            button.onclick = function () {
                Cookies.set('essential-cookies-accepted', 'true', { expires: 365 });
                Cookies.set('cookies-accepted', 'false', { expires: 365 });
                location.reload();
            };
        });
    }

    if (Cookies.get('cookies-accepted') === 'true') {
        //
    } else {
        for (let i = 0; i < document.getElementsByTagName('iframe').length; i++) {
            let src = document.getElementsByTagName('iframe')[i].getAttribute('src');
            // Todo: Youtube in code? Should be from settings/code replacement in CMS
            if ((src !== null) && (src.indexOf('youtube') > -1)) {
                let clone = document.getElementsByClassName('block-media-notification')[0].cloneNode(true);
                clone.className += ' active';

                document.getElementsByTagName('iframe')[i].outerHTML = clone.outerHTML;
            }
        }
    }
}

for (let i = 0; i < document.getElementsByClassName('js-change-cookie-settings').length; i++) {
    document.getElementsByClassName('js-change-cookie-settings')[i].onclick = function () {
        document.getElementsByTagName('body')[0].classList.remove('cookie-control--inverse');

        document.getElementsByTagName('body')[0].className += ' cookie-control--edit';

        Array.prototype.forEach.call(document.getElementsByClassName('js-cookie-confirmation'), function (button) {
            button.onclick = function () {
                Cookies.set('essential-cookies-accepted', 'true', { expires: 365 });
                Cookies.set('cookies-accepted', 'true', { expires: 365 });
                location.reload();
            };
        });

        Array.prototype.forEach.call(document.getElementsByClassName('js-cookie-confirmation-essentials'), function (button) {
            button.onclick = function () {
                Cookies.set('essential-cookies-accepted', 'true', { expires: 365 });
                Cookies.set('cookies-accepted', 'false', { expires: 365 });
                location.reload();
            };
        });
    }
}