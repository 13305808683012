export default function () {
    $('.check__zip-code').each(function (index) {
        if ( ! $(this).hasClass('check__zip-code--initialised')) {
            let inputZipCode = $(this).find('input[data-inputsort="zipcode"]');
            let inputNumber = $(this).find('input[data-inputsort="streetnumber"]');
            let inputResidence = $(this).find('input[data-inputsort="residence"]');
            let inputStreet = $(this).find('input[data-inputsort="street"]');


            inputResidence.prop('readonly', true);
            inputStreet.prop('readonly', true);
            inputZipCode.prop('readonly', true);
            inputNumber.prop('readonly', true);

            inputZipCode.on('change', function () {
                getStreetAndPlace(inputZipCode, inputNumber, inputResidence, inputStreet);
            });

            inputNumber.on('change', function () {

                let number = (typeof inputNumber !== 'undefined') ? inputNumber.val() : null;
                if ((/^\d/).test(number)) {
                    getStreetAndPlace(inputZipCode, inputNumber, inputResidence, inputStreet);
                    inputNumber.removeClass('error');
                    inputNumber.closest('.inputfield').find('.error__text').html('');
                } else {
                    inputNumber.addClass('error');
                    inputNumber.closest('.inputfield').find('.error__text').html('Een huisnummer kan niet beginnen met een letter.');
                }
            });

            $('.change-address').on('click', function(){
                inputResidence.prop('readonly', false);
                inputStreet.prop('readonly', false);
            });

            getStreetAndPlace(inputZipCode, inputNumber, inputResidence, inputStreet);

            $(this).addClass('check__zip-code--initialised');
        }
    });

    function getStreetAndPlace(inputZipCode, inputNumber, inputResidence, inputStreet) {
        let zipCode = (typeof inputZipCode !== 'undefined') ? inputZipCode.val() : null;
        let number = (typeof inputNumber !== 'undefined') ? inputNumber.val() : null;
        zipCode = zipCode.replace(/\s/g, '');
        number = number.replace(/\s/g, '');

        if (zipCode && (/^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i).test(zipCode) && number) {
            $.ajax({
                url: "https://geodata.nationaalgeoregister.nl/locatieserver/free?fq=postcode:" + zipCode.replace(/\s/g, '') + "&fq=huisnummer:" + number.split(/[^\d,]/g)[0],
                success: function (result) {
                    if (result.response.numFound > 0) {
                        inputStreet.val(result.response.docs[0].straatnaam);
                        inputStreet.attr('value', result.response.docs[0].straatnaam);
                        inputResidence.val(result.response.docs[0].woonplaatsnaam);
                        inputResidence.attr('value', result.response.docs[0].woonplaatsnaam);
                        inputStreet.addClass('has-value');
                        inputResidence.addClass('has-value');
                        inputZipCode.addClass('has-value');
                        inputNumber.addClass('has-value');
                        inputResidence.prop('readonly', true);
                        inputStreet.prop('readonly', true);
                        inputZipCode.prop('readonly', true);
                        inputNumber.prop('readonly', true);
                        inputZipCode.removeClass('error');
                        inputNumber.removeClass('error');
                        inputStreet.closest('.inputfield').find('.error__text').html('');
                        $('.change-address').css('display', 'block');
                    } else {
                        inputZipCode.addClass('error');
                        inputNumber.addClass('error');
                        inputStreet.closest('.inputfield').find('.error__text').html('Geen overeenkomst gevonden. Kloppen bovenstaande gegevens? Dan vul handmatig het adres in.');
                        inputResidence.prop('readonly', false);
                        inputStreet.prop('readonly', false);
                        inputZipCode.prop('readonly', false);
                        inputNumber.prop('readonly', false);
                        $('.change-address').css('display', 'block');
                    }
                }, error: function (response) {
                    inputResidence.prop('readonly', false);
                    inputStreet.prop('readonly', false);
                    inputZipCode.removeClass('error');
                    inputNumber.removeClass('error');
                }
            });
        }
        else {
            inputResidence.prop('readonly', false);
            inputStreet.prop('readonly', false);
            inputZipCode.prop('readonly', false);
            inputNumber.prop('readonly', false);
        }
    }
}