window.$ = window.jQuery = require('jquery');
window.Cookies = require('js-cookie');

require('./vendor/jqueryUI');

require('./vendor/doubleTapToGo');
require('./vendor/fancybox');
require('slick-carousel');
require('./vendor/match-height');
require('./vendor/oldbasket');
require('./scripts/cookie');
require('./scripts/event');

import {Fef} from './classes/Fef';
import zipCode from './scripts/zipCode';
import 'simplebar';
import {FormElements} from './classes/FormElements';

// Webshop includes
import section from './scripts/section';
import {Basket} from './classes/Basket';
import articles from './scripts/articles';
import filters from './scripts/filters';

/**
 * Main Scripts
 *
 * This main scripts file is where you'll write most
 * of your (jQuery) scripts.
 */

(function ($) {
    /**
     * Initialise the Front-end Framework.
     *
     * @type {Fef}
     */
    let fef = new Fef({
        matchHeightSite: () => {
        },
    });

    // Fancybox
    $(".fancybox, .lightbox").fancybox({
        // Options will go here
    });

    //shorten description
    $(".js-toggler hr").each(function () {
        $(this).nextAll().wrapAll('<div class="toggle-this"></div>');
    });

    $(".js-toggler").each(function () {
        if ($(this).find('hr').length === 0) {
            $(this).parent().find('.toggle-this').addClass('');
        }
    });

    // Toggle action models
    $('.js-toggler .toggle-this').hide();
    $(".headlines .js-toggle-more").on('click', function () {
        $(this).closest('.content__col').find(".js-toggler .toggle-this").slideToggle("slow", function () {
            if ($(this).closest('.content__col').find('.js-toggler').hasClass('open')) {
                $(this).closest('.toggle-this').removeClass('open');
                $(this).closest('.js-toggler').removeClass('open');
                $(this).closest('.content__col').find('.js-toggle-more').html('<i class="fal fa-arrow-down"></i>');
            } else {
                $(this).closest('.toggle-this').addClass('open');
                $(this).closest('.js-toggler').addClass('open');
                $(this).closest('.content__col').find('.js-toggle-more').html('<i class="fal fa-arrow-up"></i>');
            }
        });
    });

    $(window).resize(function () {
        if ($(window).outerWidth() > fef.config.breakpoints.sm) {
            $('.nav .nav__item:has(ul)').doubleTapToGo();
        }

        if ($(window).outerWidth() < fef.config.breakpoints.md) {
            $(".nav .nav__item .nav__link").click(function () {
                $("body").removeClass("menu-is-active");
            });
        }
    });

    if ($(window).outerWidth() < fef.config.breakpoints.md) {
        $(".nav .nav__item .nav__link").click(function () {
            $("body").removeClass("menu-is-active");
        });
    }

})(jQuery);